@font-face {
  font-family: "CerebriSans";
  font-weight: 700;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-Book.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-style: italic;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-BookItalic.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-weight: 800;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-style: italic;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-weight: 500;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-weight: 400;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-weight: 600;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "CerebriSans";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: local("CerebriSans"),
    url(./Common/assets/fonts/CerebriSans-SemiBoldItalic.ttf) format("truetype");
}